<template>
  <v-container class="pa-16">
    <template>
      <v-toolbar flat extended extension-height="100">
        <v-toolbar-title id="toolbar-title">Suivi des Congés</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="418px">
          <template v-slot:activator="{}">
            <Button
              action_name="Exporter"
              class="mb-2"
              color="primary"
              text
              @clicked="exportData"
              :loading="loadingExport"
            >
              <template v-slot:button-content>
                <v-img :src="require('@/assets/export.svg')" alt="humberger" />
              </template>
            </Button>
          </template>
        </v-dialog>
        <v-dialog v-model="filterDialog" max-width="836px">
          <v-card>
            <v-card-title class="justify-space-between">
              <span class="headline">Filtre</span>
              <v-btn color="black" dark icon right @click="close">
                <v-icon color="#B2BBAB">mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" md="6" sm="6" class="ma-0 pt-0 pb-0">
                    <AutoComplete
                      id="agents"
                      v-model="editedItem.agents"
                      :items="agents"
                      label="Agent"
                      item-text="name"
                      return-object
                      multiple
                      deletable-chips
                      small-chips
                    />
                  </v-col>
                  <v-col cols="12" md="6" sm="6" class="ma-0 pt-0 pb-0">
                    <AutoComplete
                      id="year"
                      v-model="editedItem.year"
                      :items="years"
                      label="Année"
                      item-text="name"
                      return-object
                    />
                  </v-col>
                  <v-col cols="12" md="6" sm="6" class="ma-0 pt-0 pb-0">
                    <AutoComplete
                      id="type"
                      v-model="editedItem.type"
                      :items="congeTypes"
                      label="Type"
                      item-text="name"
                      return-object
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions class="pb-6">
              <Button
                action_name="Filtrer"
                color="#86CC26"
                @clicked="save"
                style="width: 220px"
                class="ml-5 mr-5"
                :loading="loading"
              />
              <span style="color:#B2BBAB" @click="handleReset"
                >Réinitialiser</span
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <template v-slot:extension>
          <div class="filter-block">
            <div>
              <template v-if="editedItem.year">
                <v-chip class="ma-1">Année:{{ editedItem.year }}</v-chip>
              </template>
              <template v-if="editedItem.type">
                <v-chip class="ma-1">Type:{{ editedItem.type }}</v-chip>
              </template>
              <template v-for="(agent, index) in editedItem.agents">
                <v-chip
                  class="ma-1"
                  :key="agent.name"
                  close
                  @click:close="handleChipDelete(index, 'agents')"
                  >Agent:{{ agent.name }}</v-chip
                >
              </template>
            </div>
            <div>
              <v-chip class="chip-delete">
                <v-icon @click="filterDialog = true" outlined>
                  mdi-filter
                </v-icon>
              </v-chip>
            </div>
          </div>
        </template>
      </v-toolbar>
    </template>

    <v-row justify="center" class="mt-5">
      <div class="table-container">
        <table class="custom-table">
          <thead>
            <tr>
              <th v-for="cell in tableData[0]['dataHeaderTable']" :key="cell">
                {{ cell }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(row, rowIndex) in tableData[0]['dataBodyTable']"
              :key="rowIndex"
            >
              <td v-for="(item, cellIndex) in row" :key="cellIndex">
                {{ item }}
              </td>
            </tr>
            <tr>
              <td
                v-for="(item, cellIndex) in tableData[0]['dataFooterTable']"
                :key="cellIndex"
              >
                {{ item }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </v-row>
  </v-container>
</template>
<script>
import { Button, AutoComplete } from "@/components/base";
import { infinityScrollMixin } from "@/utils/infinityScrollMixin";
import { getYears } from "@/services/timesheet.api";
import { getEvents, exportAllEvent } from "@/services/conge.api";
import { getActiveUsers } from "@/services/getActiveUsers.api";
import congeTypes from "@/assets/data/congeTypes.json";
export default {
  components: { Button, AutoComplete },
  mixins: [infinityScrollMixin],
  data: () => ({
    dialog: false,
    loadingExport: false,
    editedItem: { year: new Date().getFullYear(), type: "Congé payé" },
    filterDialog: false,
    agents: [],
    years: [],
    loading: false,
    editedIndex: -1,
    congeTypes: congeTypes,
    tableData: {
      0: { dataHeaderTable: [], dataBodyTable: [], dataFooterTable: [] },
    },
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      getActiveUsers().then((response) => {
        this.agents = response.data.users;
      });
      getYears().then((response) => {
        this.years = response.data.years;
      });
      getEvents(this.editedItem).then((response) => {
        this.tableData = Object.values(response.data);
      });
    },
    exportData() {
      this.loadingExport = true;
      exportAllEvent(this.editedItem)
        .then((response) => {
          this.loadingExport = false;

          const blob = new Blob([response.data], { type: "application/csv" });
          const link = document.createElement("a");

          link.href = window.URL.createObjectURL(blob);
          link.download = `liste_des_congés.csv`;

          document.body.appendChild(link);

          link.click();

          document.body.removeChild(link);
        })
        .catch((error) => {
          this.handleFail(error);
        });
    },
    close() {
      this.filterDialog = false;
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign(
          { year: this.editedItem.year, type: this.editedItem.type },
          this.defaultItem
        );
        this.editedIndex = -1;
      });
    },
    handleChipDelete(index, key) {
      this.editedItem[key].splice(index, 1);
      this.save();
    },
    handleReset() {
      this.editedItem = { year: new Date().getFullYear(), type: "Congé payé" };
      this.save();
    },
    save() {
      this.loading = true;
      getEvents(this.editedItem).then((response) => {
        this.loading = false;
        this.tableData = Object.values(response.data);
        this.dialog = false;
        this.filterDialog = false;
      });
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
};
</script>
<style scoped>
.table-container {
  max-width: 100%;
  overflow: auto;
  max-height: 600px;
}
.custom-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  table-layout: fixed;
}

.custom-table th,
.custom-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: center;
  color: black;
  font-size: 13px;
  white-space: nowrap;
}
.custom-table th {
  color: white;
  background-color: #86cc26;
  font-size: 15px;
  width: 120px;
  position: sticky;
  top: 0;
  z-index: 3;
}
.custom-table tbody tr:last-child td {
  font-weight: bold;
  font-size: 14px;
}
.custom-table td:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: #fff;
}
.custom-table th:first-child {
  position: sticky;
  left: 0;
  z-index: 4;
  width: 150px;
}
</style>

import httpClient from "./httpClient";

const END_POINT = "/api";

const getEvents = (filterList) =>
    httpClient.post(END_POINT + "/getEvents", filterList);
const exportAllEvent = (filterList) =>
    httpClient.post(END_POINT + "/exportAllEvent", filterList, {
        responseType: "blob",
        filterList,
    });
export { getEvents, exportAllEvent };
